import type { UserGroupName } from '@tbd/simcapture-api-types';
import type { LiftUserType } from './util/users.tsx';
import type { AuthenticatedUser } from './auth.server';

type UserTypeParams =
  | { userType: LiftUserType }
  | { userGroup: { name: UserGroupName } }
  | { userGroupTypeRef: LiftUserType }
  | { userGroups: Array<{ name: UserGroupName }> };
export function isAdminUser(user: AuthenticatedUser) {
  return user ? isFacilitator(user) || isMentor(user) : false;
}
export function isFacilitator(user?: UserTypeParams) {
  if (!user) return false;
  if ('userType' in user) {
    return user.userType === 'Administrator';
  }
  if ('userGroup' in user) {
    return user.userGroup.name === 'Administrator';
  }
  if ('userGroupTypeRef' in user) {
    return user.userGroupTypeRef === 'Administrator';
  }
  if ('userGroups' in user) {
    return (
      Array.isArray(user.userGroups) &&
      user?.userGroups[0]?.name === 'Administrator'
    );
  }
  return false;
}

export function isMentor(user?: UserTypeParams) {
  if (!user) return false;
  if ('userType' in user) {
    return user.userType === 'SystemAdmin';
  }
  if ('userGroup' in user) {
    return user.userGroup.name === 'System Admin';
  }
  if ('userGroupTypeRef' in user) {
    return user.userGroupTypeRef === 'SystemAdmin';
  }
  if ('userGroups' in user) {
    return (
      Array.isArray(user?.userGroups) &&
      user?.userGroups[0]?.name === 'System Admin'
    );
  }
  return false;
}

export function isParticipant(user?: UserTypeParams) {
  if (!user) return false;
  if ('userType' in user) {
    return user.userType === 'Participant';
  }
  if ('userGroup' in user) {
    return user.userGroup.name === 'Participant';
  }
  if ('userGroupTypeRef' in user) {
    return user.userGroupTypeRef === 'Participant';
  }
  if ('userGroups' in user) {
    return (
      Array.isArray(user?.userGroups) &&
      user?.userGroups[0]?.name === 'Participant'
    );
  }
  return false;
}

const ADMIN_ROOT = '' as const; // will be '/admin' when those routes are ported
const PARTICIPANT_ROOT = '/participant' as const;
export function getRoleRoot(user: UserTypeParams) {
  if (isMentor(user) || isFacilitator(user)) return ADMIN_ROOT;
  if (isParticipant(user)) return PARTICIPANT_ROOT;
  console.error('unhandled userType', user);
  return '';
}

export function getUserStartPath(user: UserTypeParams) {
  const roleRoot = getRoleRoot(user);
  const startPath = `${roleRoot}/sessions`;

  return startPath;
}
